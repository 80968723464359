import React, { useState, Fragment } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import loginRequest from './actions'
import { Link, Navigate } from 'react-router-dom'

import '../assets/sass/style.scss'
import '../assets/sass/plugins.scss'
import '../assets/sass/style.react.scss'




const Login = () => {

  const login = useSelector(state => state.login);
  const client = useSelector(state=> state.client);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
 
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };


  const dispatch = useDispatch();


  const handleSubmit = (e) => {
    e.preventDefault();
   

    const values = {
      email,
      password
      }

    dispatch(loginRequest(values)); 
  };

  let {requesting, successful, messages, errors } = login;

  return (
    <Fragment>
      {
        successful ? (
        
          client.decode ? 
          <Navigate to={{ pathname: '/reservas', state: ''}} />
          :
          <Navigate to={{ pathname: '/', state: ''}} />
          
          ) : null
      }
    <form onSubmit={handleSubmit} className="checkin-form">

        <label className="checkin-label">
        Email:
        </label>
        <input type="email" value={email} onChange={handleEmailChange} className="checkin-input" required/>
      
        <label className="checkin-label">
        Password:
        </label>
        <input type="password" value={password} onChange={handlePasswordChange} className="checkin-input" required/>
        
      <button type="submit" className="checkin-button">Login</button>
      <br/>
      <div className="auth-messages">
            {/* As in the signup, we're just using the message and error helpers */}
            {!requesting && !!errors.length && (
              <div>Los datos de acceso no son correctos.</div>
            )}
            
            
        </div>
    </form>
    </Fragment>
  );
};

export default Login;