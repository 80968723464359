export const fetchData = () => ({
  type: "FETCH_DATA"
});

export const fetchDataSuccess = (data) => ({
  type: "FETCH_DATA_SUCCESS",
  data
});

export const obtenerDatosVivienda = (data) => ({
  type: "OBTENER_DATOS_VIVIENDA",
  payload: data
});

export const obtenerDatosViviendaSuccess = (data) => ({
  type: "OBTENER_DATOS_VIVIENDA_SUCCESS",
  data
});

export const activarPendiente = (data) => ({
  type: "ACTIVAR_PENDIENTE",
  payload: data
});
