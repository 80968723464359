import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ENVIAR_NUMHUESPEDES,
  ENVIAR_NUMHUESPEDES_EXITO,
  ENVIAR_NUMHUESPEDES_ERROR,
  OBTENER_INFORMACION,
  OBTENER_INFORMACION_SUCCESS,
  ELIMINAR_HUESPED,
  ELIMINAR_HUESPED_SUCCESS
} from './constants'

import { obtenerInformacionViviendaSuccess, eliminarHuespedSuccess } from "./actions";

  function* enviarNumHuespedesSaga(action) {

    const id = window.localStorage.getItem('idSaga')

    const url = `${process.env.REACT_APP_API_BASE_URL}/api/reservas/${id}`;
  
    try {
      const response = yield call(fetch, url, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/ld+json' },
        body: JSON.stringify(action.informacion),
      });
  
      const data = yield response.json();
      yield put({ type: ENVIAR_NUMHUESPEDES_EXITO, data });
    } catch (error) {
      yield put({ type: ENVIAR_NUMHUESPEDES_ERROR, error });
    }
  }

  function* obtenerInformacionSaga() {
    const id = window.localStorage.getItem('idSaga')
  
    const url = `${process.env.REACT_APP_API_BASE_URL}/api/reservas/${id}`;
    
    try {
      const response = yield call(fetch, url);
      const data = yield response.json();
      yield put(obtenerInformacionViviendaSuccess(data));
    } catch (error) {
      console.error(error);
    }
  }

  function* eliminarHuespedSaga(action) {
 
    //  const api = ["/api/huespeds/70", "/api/huespeds/68", "/api/huespeds/71"]
    const api = action.payload
  
     const data = [];
     
      try {
        
        for (let i = 0; i < api.length; i++) {
        
        const url = `${process.env.REACT_APP_API}${api[i]}`;
        const response = yield call(fetch, url, {
          method: 'DELETE'
        });
        }
        yield put(eliminarHuespedSuccess(data));
      } catch (error) {
        console.error(error);
      }
  }

  
  export default function* rootSaga() {
    yield takeLatest('ENVIAR_NUMHUESPEDES', enviarNumHuespedesSaga);
    yield takeLatest(OBTENER_INFORMACION, obtenerInformacionSaga);
    yield takeLatest(ELIMINAR_HUESPED, eliminarHuespedSaga);
  }