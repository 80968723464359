import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_VIVIENDAS_REQUEST,
  FETCH_VIVIENDAS_SUCCESS,
  FETCH_VIVIENDAS_FAILURE,
} from './constants';
import {
  fetchViviendasSuccess,
  fetchViviendasFailure,
} from './actions';

// Función para hacer la solicitud a la API
function* fetchViviendas() {
  try {
    const response = yield call(fetch, `${process.env.REACT_APP_API_BASE_URL}/api/viviendas`);
    const data = yield response.json();
    const collection = data['hydra:member'];
    yield put(fetchViviendasSuccess(collection));
  } catch (error) {
    yield put(fetchViviendasFailure(error));
  }
}

// Escucha la acción FETCH_VIVIENDAS_REQUEST y llama a fetchViviendas
export default function* rootSaga() {
  yield takeLatest(FETCH_VIVIENDAS_REQUEST, fetchViviendas);
}
