import React from "react";
import FilaListaReservas from "./filaListaReservas";
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom'

const ListaReservas = ({ datosReservas, className, username}) => {

  const navigate = useNavigate();

  return (

    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Usuario: {username}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'><button  onClick={ (e) => {
                                e.preventDefault();
                                navigate('/logout');
                            }}className='btn btn-sm btn-light-primary'>Log out</button></span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Configuracion'
        >
          {/* <a
            href='#'
            className='btn btn-sm btn-light-primary'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
          >
            
            Configuracion
          </a> */}
          <button  onClick={ (e) => {
                                e.preventDefault();
                                navigate('/micuenta');
                            }}className='btn btn-sm btn-light-primary'>Mi Cuenta</button>
        <button  onClick={ (e) => {
                                e.preventDefault();
                                navigate('/configuracion');
                            }}className='btn btn-sm btn-light-primary'>Configuracion</button>
        </div>
        
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px'>Vivienda</th>
                <th className='min-w-140px'>Fecha Entrada</th>
                <th className='min-w-140px'>Fecha Salida</th>
                <th className='min-w-120px'>Progreso</th>
                <th className='min-w-120px'>Notificado Ministerio</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>

              {datosReservas.map((item, index) => (
                <FilaListaReservas index={index} item={item}/>
               ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>

    
  )
};

export default ListaReservas;