import { call, put, takeLatest } from 'redux-saga/effects';

import {
  ENVIAR_HUESPED,
  ENVIAR_HUESPED_EXITO,
  ENVIAR_HUESPED_ERROR,
  EDITAR_HUESPED,
  EDITAR_HUESPED_EXITO,
  EDITAR_HUESPED_ERROR,
  OBTENER_INFORMACION_HUESPED,
  OBTENER_INFORMACION_HUESPED_SUCCESS
} from './constants'

import {
  OBTENER_INFORMACION,
} from '../viviendas/constants'

import { obtenerInformacionHuespedSuccess } from "./actions";



function* enviarHuespedSaga(action) {
  try {
    const response = yield call(fetch, `${process.env.REACT_APP_API_BASE_URL}/api/huespeds`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/ld+json' },
      body: JSON.stringify(action.informacion),
    });

    const data = yield response.json();
    yield put({ type: ENVIAR_HUESPED_EXITO, data });
    //actualizar viviendas
    yield put({ type: OBTENER_INFORMACION })
  } catch (error) {
    yield put({ type: ENVIAR_HUESPED_ERROR, error });
  }
}

function* editarHuespedSaga(action) {

  const id = window.localStorage.getItem('idSaga')

  const url = `${process.env.REACT_APP_API_BASE_URL}/api/reservas/${id}`;
  try {
    const response = yield call(fetch, url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/ld+json' },
      body: JSON.stringify(action.informacion),
    });

    const data = yield response.json();
    yield put({ type: EDITAR_HUESPED_EXITO, data });
    //actualizar 
    // yield put({ type: OBTENER_INFORMACION_HUESPED })
  } catch (error) {
    yield put({ type: EDITAR_HUESPED_ERROR, error });
  }
}

function* obtenerInformacionHuespedSaga(action) {
 
  //  const api = ["/api/huespeds/70", "/api/huespeds/68", "/api/huespeds/71"]
  const api = action.payload

   const data = [];
   
    try {
      
      for (let i = 0; i < api.length; i++) {
      
      const url = `${process.env.REACT_APP_API}${api[i]}`;

      const response = yield call(fetch, url);
      const huesped = yield response.json();
      data.push(huesped);

      }
      yield put(obtenerInformacionHuespedSuccess(data));
    } catch (error) {
      console.error(error);
    }
}




export default function* rootSaga() {
  yield takeLatest('ENVIAR_HUESPED', enviarHuespedSaga);
  yield takeLatest('EDITAR_HUESPED', editarHuespedSaga);
  yield takeLatest('OBTENER_INFORMACION_HUESPED', obtenerInformacionHuespedSaga);
 
}
