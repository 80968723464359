import React, { useState } from 'react';
import './Demo.css'; // importamos el archivo CSS
import {useDispatch, useSelector} from 'react-redux';
import { enviarInformacion,  } from './actions';
import InputText from '../componentes/formulario/inputText';

import '../assets/sass/style.scss'
import '../assets/sass/plugins.scss'
import '../assets/sass/style.react.scss'




const Demo = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [checkInDate, setCheckInDate] = useState('');
  const [checkOutDate, setCheckOutDate] = useState('');
  const [hasError, setHasError] = useState(false)

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleCheckInDateChange = (e) => {
    setCheckInDate(e.target.value);
  };

  const handleCheckOutDateChange = (e) => {
    setCheckOutDate(e.target.value);
  };

  const dispatch = useDispatch();


  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí podrías enviar la información del check-in al servidor para guardarla en la base de datos
    // y luego mostrar una confirmación al usuario de que su check-in se ha completado con éxito
    
    //prueba para api Fran
    // const informacion = {
    //   nombre: "Francisco",
    //   apellido: "Sanchez"
    // }

    const informacion = {
      name, 
      email,
      checkInDate,
      checkOutDate
      }

    dispatch(enviarInformacion(informacion));

 
    
  };

  return (
    <form onSubmit={handleSubmit} className="checkin-form">

      <InputText
      label="Nombre"
      value={name} 
      name="nombre"
      handleChange={handleNameChange}
      hasError={false}
      errorText="Specify your unique app name"
      title="Nombre is required"
      />
     
     
      <label className="checkin-label">
        Email:
        <input type="email" value={email} onChange={handleEmailChange} className="checkin-input" />
      </label>
      <label className="checkin-label">
        Fecha de entrada:
        <input type="date" value={checkInDate} onChange={handleCheckInDateChange} className="checkin-input" />
      </label>
      <label className="checkin-label">
        Fecha de salida:
        <input type="date" value={checkOutDate} onChange={handleCheckOutDateChange} className="checkin-input" />
      </label>
      <button type="submit" className="checkin-button">Check-in</button>
    </form>
  );
};

export default Demo;