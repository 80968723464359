import React, { useState,useEffect } from "react";
import moment from 'moment';
import { obtenerDatosVivienda } from "../../reservas/actions";
import {useDispatch, useSelector} from 'react-redux';


const InputNombreVivienda = ({ nombre }) => {

    const dispatch = useDispatch();

    const data = useSelector(state => state.reservas.vivienda);

    const [vivienda, setVivienda] = useState('');

    useEffect(() => {
        dispatch(obtenerDatosVivienda(nombre));
    }, []);

    useEffect(() => {
        if (data) {
            
            fetch(`${process.env.REACT_APP_API}` + nombre)
            .then(response => response.json())
            .then(data => {
              setVivienda(data.nombre)
            });
        }
    
      }, [data]);

    
    



  return (
            
            <div className='d-flex align-items-center'>
                <div className='symbol symbol-45px me-5'>
                {/* <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' /> */}
                </div>
                <div className='d-flex justify-content-start flex-column'>
                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                {vivienda}
                </a>
                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    Nombre vivienda
                </span>
                </div>
            </div>
           
    )
};

export default InputNombreVivienda;