/*
import SignupSaga from './registro/sagas'
import LoginSaga from './login/sagas'
import DashboardSaga from './dashboard/sagas'
import CampaginSaga from './campaign/sagas'
import DoblefactorSaga from './doblefactor/sagas'
import DatosCuenta from './datoscuenta/sagas'
import DasboardInfluencer from './dashboard_influencer/sagas'
import Planes from './planes_comprar/sagas'
import RecoverySaga from './password_recovery/sagas'
*/
import Demo from './demo/sagas'
import Reservas from "./reservas/sagas";
import Viviendas from "./viviendas/sagas";
import Huespedes from "./huespedes/sagas";
import LoginSaga from './login/sagas'
import MicuentaSaga from './micuenta/sagas'
import { all } from 'redux-saga/effects'

export default function* IndexSaga () {
  yield all ([
    /*LoginSaga(),
    //WidgetSaga(),
    SignupSaga(),
    DashboardSaga(),
    CampaginSaga(),
    DoblefactorSaga(),
    DatosCuenta(),
    Planes(),
    DasboardInfluencer(),
    RecoverySaga()
    */
    Demo(),
    Reservas(),
    Viviendas(),
    Huespedes(),
    LoginSaga(),
    MicuentaSaga()
  ])
}
