import {
    ENVIAR_INFORMACION,
    ENVIAR_INFORMACION_EXITO,
    ENVIAR_INFORMACION_ERROR
  } from './constants'

  const initialState = {
    enviando: false,
    exito: false,
    error: [],
    data: null,
  };

  const reducer = function enviarReducer (state = initialState, action) {
    switch (action.type) {
      case ENVIAR_INFORMACION:
        return { ...state, enviando: true };
      case ENVIAR_INFORMACION_EXITO:
        return {
          ...state,
          enviando: false,
          exito: true,
          error: null,
          data: action.data,
        };
      case ENVIAR_INFORMACION_ERROR:
        return {
          ...state,
          enviando: false,
          exito: false,
          error: action.error,
          data: null,
        };
      default:
        return state;
        }
}
  
  export default reducer