import { call, put, takeLatest } from 'redux-saga/effects';

import {
  ENVIAR_INFORMACION,
  ENVIAR_INFORMACION_EXITO,
  ENVIAR_INFORMACION_ERROR
} from './constants'

// Helper for api errors
import { handleApiErrors } from '../lib/api-errors'

function* enviarHuespedSaga(action) {
  try {
    const response = yield call(fetch, `${process.env.REACT_APP_API_BASE_URL}/api/usuarios`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/ld+json' },
      body: JSON.stringify(action.informacion),
    });

    const data = yield response.json();
    yield put({ type: ENVIAR_INFORMACION_EXITO, data });
  } catch (error) {
    yield put({ type: ENVIAR_INFORMACION_ERROR, error });
  }
}

export default function* rootSaga() {
  yield takeLatest('ENVIAR_INFORMACION', enviarHuespedSaga);
}