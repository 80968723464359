import React, { useState,useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { enviarDatoshuesped, obtenerInformacionHuesped } from '../huespedes/actions';

const Formulario = ({ index, reserva }) => {
  //Variables del formulario (huespedes)
  const [nombre, setNombre] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [apellidos2, setApellidos2] = useState('');
  const [telefono, setTelefono] = useState('');
  const [email, setEmail] = useState('');
  const [nacimiento, setNacimiento] = useState('');
  const [identificacion, setIdentificacion] = useState('');
  const [tipoIdentificacion, setTipoIdentificacion] = useState('');

  //Obtener variables del formulario
  const handleNombreChange = (e) => {
    setNombre(e.target.value);
  };

  const handleApellidosChange = (e) => {
    setApellidos(e.target.value);
  };

  const handleApellidos2Change = (e) => {
    setApellidos2(e.target.value);
  };
  
  const handleTelefonoChange = (e) => {
    setTelefono(e.target.value);
  };
  
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleNacimientoChange = (e) => {
    setNacimiento(e.target.value);
  };

  const handleTipoIdentificacionChange = (e) => {
    setTipoIdentificacion(e.target.value);
  };

  const handleIdentificacionChange = (e) => {
    setIdentificacion(e.target.value);
  };

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    const informacion = {
        nombre: nombre,
        apellidos: apellidos,
        apellidos2: apellidos2,
        telefono: telefono,
        email: email,
        fechaNacimiento: nacimiento,
        identificacion: identificacion,
        tipoIdentificacion: tipoIdentificacion,
        reserva: reserva,

      }
  
    dispatch(enviarDatoshuesped(informacion));

    setNombre('');
    setApellidos('');
    setApellidos2('');
    setTelefono('');
    setEmail('');
    setNacimiento('');
    setIdentificacion('');
  }


  return (
    <div>
          <form onSubmit={handleSubmit} className="checkin-form">
            <h2>Datos del huesped {index + 1}</h2>
              
              <label className="checkin-label">
              Nombre:
              </label>
              <input type="text" value={nombre} onChange={handleNombreChange} className="checkin-input" required />
              
              <label className="checkin-label">
              Primer Apellido:
              </label>
              <input type="text" value={apellidos} onChange={handleApellidosChange} className="checkin-input" required/>

              <label className="checkin-label">
              Segundo Apellido:
              </label>
              <input type="text" value={apellidos2} onChange={handleApellidos2Change} className="checkin-input" />
              
              <label className="checkin-label">
              Fecha de Nacimiento:
              </label>
              <input type="date" value={nacimiento}  onChange={handleNacimientoChange} className="checkin-input" required/>
              
              <label className="checkin-label">
              Tipo de Identificación:
              </label>
              <select onChange={handleTipoIdentificacionChange} className="checkin-input">
                  <option value="NIF">NIF - Número de Identificación Fiscal</option>
                  <option value="NIE">NIE - Número de Identidad de Extranjero</option>
                  <option value="PAS">Pasaporte</option>
                  <option value="OTRO">Otro</option>
              </select>
              
              <label className="checkin-label">
              Número:
              </label>
              <input type="text" value={identificacion} onChange={handleIdentificacionChange} className="checkin-input" required/>

              <label className="checkin-label">
              Teléfono:
              </label>
              <input type="text" value={telefono} onChange={handleTelefonoChange} className="checkin-input" />

              <label className="checkin-label">
              E-mail:
              </label>
              <input type="email" value={email} onChange={handleEmailChange} className="checkin-input" required/>
                          
              <button type="submit" className="checkin-button">Check-in</button>
          
          </form>
          <br />
          <br />
        </div>
  );
};

export default Formulario;