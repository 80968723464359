export const enviarNumHuespedes = (informacion) => ({
  type: 'ENVIAR_NUMHUESPEDES',
  informacion,
});

export const obtenerInformacionVivienda = () => ({
  type: "OBTENER_INFORMACION"
});

export const obtenerInformacionViviendaSuccess = (data) => ({
  type: "OBTENER_INFORMACION_SUCCESS",
  data
});

export const eliminarHuesped = (data) => ({
  type: "ELIMINAR_HUESPED",
  payload: data
});

export const eliminarHuespedSuccess = (data) => ({
  type: "ELIMINAR_HUESPED_SUCCESS",
  data
});

