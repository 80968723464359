import React, { useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';

const InputText = ({label, value, name, handleChange, hasError, errorText, title}) =>{

    return (
        <div className='w-100'>
        {/*begin::Form Group */}
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>{label}</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title={title}
            ></i>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name={name}
            placeholder=''
            value={value}
            onChange={(e) =>
              handleChange(e)
            }
          />
          {!value && hasError && (
            <div className='fv-plugins-message-container'>
              <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                {errorText}
              </div>
            </div>
          )}
        </div>
        {/*end::Form Group */}
        </div>
      
    )
    
}

export default InputText;