import {
    FETCH_VIVIENDAS_REQUEST,
    FETCH_VIVIENDAS_SUCCESS,
    FETCH_VIVIENDAS_FAILURE,
  } from './constants';
  
  const initialState = {
    viviendas: [],
    loading: false,
    error: null,
  };

    const reducer = function viviendasReducer (state = initialState, action) {
    switch (action.type) {
      case FETCH_VIVIENDAS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_VIVIENDAS_SUCCESS:
        return {
          ...state,
          loading: false,
          viviendas: action.payload,
          error: null,
        };
      case FETCH_VIVIENDAS_FAILURE:
        return {
          ...state,
          loading: false,
          viviendas: [],
          error: action.error,
        };
      default:
        return state;
    }
  };
  
  export default reducer;