import {
  FETCH_DATA,
  FETCH_DATA_SUCCESS,
  OBTENER_DATOS_VIVIENDA,
  OBTENER_DATOS_VIVIENDA_SUCCESS,
  ACTIVAR_PENDIENTE
} from './constants'

const initialState = {
  exito: false,
  error: [],
  data: null,
  vivienda: null,
};

const reducer = function enviarReducer (state = initialState, action) {
  switch (action.type) {
    case FETCH_DATA:
        return { ...state};
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        exito: true,
        error: null,
        data: action.data,
      };
    case OBTENER_DATOS_VIVIENDA:
        return { ...state};
    case OBTENER_DATOS_VIVIENDA_SUCCESS:
      return {
        ...state,
        exito: true,
        error: null,
        data: null,
        vivienda: action.data
      };
    case ACTIVAR_PENDIENTE:
      return { ...state};
    default:
      return state;
  }
};

export default reducer;