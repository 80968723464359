import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchViviendasRequest } from './actions';

const Micuenta = () => {
  const dispatch = useDispatch();
  const viviendas = useSelector(state => state.micuenta.viviendas);
  const loading = useSelector(state => state.micuenta.loading);
  const error = useSelector(state => state.micuenta.error);

  // Estado local para almacenar los datos de la vivienda seleccionada
  const [viviendaSeleccionada, setViviendaSeleccionada] = useState({
    nombre: '',
    direccion: '',
    icalAirbnb: '',
    icalBooking: '',
  });

  useEffect(() => {
    // En el montaje del componente, envía la acción para obtener las viviendas
    dispatch(fetchViviendasRequest());
  }, [dispatch]);

  // Función para manejar el cambio en la selección del select
  const handleViviendaSeleccionada = (e) => {
    const viviendaId = e.target.value;
    const vivienda = viviendas.find((vivienda) => vivienda.id === parseInt(viviendaId, 10));
    
    // Actualizar el estado local con los datos de la vivienda seleccionada
    setViviendaSeleccionada(vivienda || {
      nombre: '',
      direccion: '',
      icalAirbnb: '',
      icalBooking: '',
    });

    const handleSubmit = (e) => {
      e.preventDefault();
    }
  };

  return (
    
    <div>
      <div className="checkin-form">
          <br />
          <button type="submit" className="checkin-button" onClick="">Añadir nuevo Ical de una nueva vivienda</button>  
          <br />
              
      </div>

      <div className="checkin-form">
          <br />
          <button type="submit" className="checkin-button" onClick="">Sincronizar Reservas</button>
          <br />
          <p>Última sincronización: 11/09/2023</p>  
              
      </div>


      {loading && <p>Cargando viviendas...</p>}
      {error && <p>Error al cargar las viviendas: {error.message}</p>}

      <form onSubmit="" className="checkin-form">
        <h2>Gestionar mis viviendas</h2>
        <br></br>
        <br></br>
        <label className="checkin-label">
          Selecciona tu vivienda
        </label>
        <select className="checkin-input" onChange={handleViviendaSeleccionada}>
        <option value="">-- Selcciona la vivienda</option>
          {viviendas.map(vivienda => (
            <option key={vivienda.id} value={vivienda.id}>
              {vivienda.nombre}
            </option>
          ))}
        </select>
        <br></br>
        <br></br>
        <label htmlFor="nombre" className="checkin-label">
          Nombre de la vivienda:
        </label>
        <input id="nombre" type="text" value={viviendaSeleccionada.nombre} className="checkin-input" />

        <label htmlFor="direccion" className="checkin-label">
          Dirección:
        </label>
        <input id="direccion" type="text" value={viviendaSeleccionada.direccion} className="checkin-input" />

        <label htmlFor="icalAir" className="checkin-label">
          Ical AirBnB:
        </label>
        <input id="icalAir" type="text" value={viviendaSeleccionada.icalAirbnb} className="checkin-input" />

        <label htmlFor="icalBooking" className="checkin-label">
          Ical Booking:
        </label>
        <input id="icalBooking" type="text" value={viviendaSeleccionada.icalBooking} className="checkin-input" />

        <button type="submit" className="checkin-button" >Editar</button> 

      </form>
      
    </div>
  );
};

export default Micuenta;
