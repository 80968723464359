import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Configuracion.css'; 

function Configuracion() {
  const [logContent, setLogContent] = useState([]);
  const version = '1.0.0'; 

  useEffect(() => {
    // Realiza una solicitud GET al endpoint que proporciona el contenido del registro
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/logs`)
      .then(response => {
        const logLines = response.data.log_content.split('\n');
        const relevantLines = logLines.filter(line => line.includes('app.INFO') || line.includes('Authenticator failed'));
        setLogContent(relevantLines);
      })
      .catch(error => {
        // Maneja cualquier error de solicitud
        console.error('Error al obtener el contenido del registro:', error);
      });
  }, []);

  return (
    <div id="conf">
    <Link to="/reservas" className="button-container">
    <button className="checkin-button" >Volver a Reservas</button>
    </Link>
    <h2>Registros Existosos y Fallidos</h2>
        <br/>
        <div id="reg">
        <ul>
            {logContent.map((line, index) => (
            <li key={index}>
                {line.includes('app.INFO') ? (
                <span className="success-message">{line}</span>
                ) : (
                <span className="red-text">{line}</span> 
                )}
            </li>
            ))}
        </ul>
        </div>
        <h1>Version {version}</h1> 
      <table class="text-dark fw-bold text-hover-primary fs-6">
        <tbody> 
          <tr>
            <td>Versión:</td>
            <td>1.1.0</td>
          </tr>
          <tr>
            <td>Fecha:</td>
            <td>06/09/2023</td> 
          </tr>
        </tbody>
      </table>
      <button className="checkin-button">Actualizar</button> 

      <h1>Clave de licencia </h1>
      <p>X7698578H - Claudia Cecilia Zwahlen Seegher </p>
      <span>Licencia validad hasta 06/09/2025</span>
      
       
    </div>
  );
}

export default Configuracion;
