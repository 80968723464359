import { call, put, takeLatest } from "redux-saga/effects";
import { fetchDataSuccess, obtenerDatosViviendaSuccess } from "./actions";


import {
  FETCH_DATA,
  FETCH_DATA_SUCCESS,
  OBTENER_DATOS_VIVIENDA,
  OBTENER_DATOS_VIVIENDA_SUCCESS,
  ACTIVAR_PENDIENTE
} from './constants'

function* fetchDataSaga() {
  try {
    const response = yield call(fetch, `${process.env.REACT_APP_API_BASE_URL}/api/reservas`);
    const data = yield response.json();
    const collection = data['hydra:member'];
    yield put(fetchDataSuccess(collection));
  } catch (error) {
    console.error(error);
  }
}

function* obtenerDatosViviendaSaga(action) {
 
  const api = action.payload

    try {
      
      const url = `${process.env.REACT_APP_API}${api}`;

      const response = yield call(fetch, url);
      const data = yield response.json();
      yield put(obtenerDatosViviendaSuccess(data));
    } catch (error) {
      console.error(error);
    }
}

function* activarPendienteSaga(action) {
  
  const id = action.payload

  try {

    const url = `${process.env.REACT_APP_API_BASE_URL}/api/reservas/${id}`;

    const response = yield call(fetch, url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        pendiente: true
      })
    });
    //actualizar reservas
    yield put({ type: FETCH_DATA })
    const data = yield call([response, 'json']);
  } catch (error) {
  }
}

export default function* rootSaga() {
  yield takeLatest(FETCH_DATA, fetchDataSaga);
  yield takeLatest('OBTENER_DATOS_VIVIENDA', obtenerDatosViviendaSaga);
  yield takeLatest(ACTIVAR_PENDIENTE, activarPendienteSaga);
}
