export const enviarDatoshuesped = (informacion) => ({
  type: 'ENVIAR_HUESPED',
  informacion,
});

export const editarDatoshuesped = (informacion) => ({
  type: 'EDITAR_HUESPED',
  informacion,
});

export const obtenerInformacionHuesped = (data) => ({
  type: "OBTENER_INFORMACION_HUESPED",
  payload: data
});

export const obtenerInformacionHuespedSuccess = (data) => ({
  type: "OBTENER_INFORMACION_HUESPED_SUCCESS",
  data
});
  
