import { CLIENT_SET, CLIENT_UNSET } from './constants'
import jwt from 'jwt-decode';
const initialSate = {
  id: null,
  token: null,
  decode: null,
  gestion_plan:{
    superado: false,
    error: null
  }
}

const reducer = function clientReducer (state = initialSate, action) {
  switch (action.type) {
    case CLIENT_SET:
      return {
        id: action.token.userId,
        token: action.token,
        decode: jwt(action.token.token),
        gestion_plan:{
          superado: false,
          error: null
        }
      }

    case CLIENT_UNSET:
      return {
        id: null,
        token: null,
        gestion_plan:{
          superado: false,
          error: null
        }
      }

    default:
      return state
  }
}

export default reducer
