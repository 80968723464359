import React, { useState,useEffect,useCallback } from "react";
import { useParams } from 'react-router-dom';
import Formulario from '../componentes/formulario'
import {useDispatch, useSelector} from 'react-redux';
import { enviarNumHuespedes, obtenerInformacionVivienda, eliminarHuesped} from './actions';
import moment from 'moment';


const Viviendas = () => {

const { id } = useParams();

  //Enviar id a Sagas
  const idSaga = id
  window.localStorage.setItem('idSaga', idSaga)
 
  //Datos de la reserva
  const [api, setApi] = useState('');
  const [numHuespedes, setNumHuespedes ] = useState('No se ha añadido el numero de huespedes');
  const [fechaInicio, setFechaInicio ] = useState('');
  const [fechaSalida, setFechaSalida ] = useState('');
  const [huespedesRegistrados, setHuespedesRegistrados ] = useState('');
  const [tipoPago, setTipoPago ] = useState('');
  

  //Datos de la Vivienda
  const [nombre, setNombre] = useState('');
  const [direccion, setDireccion] = useState('');

  //Datos formaulario de añadir/actualizacion
  const [tipoPagoAct, setTipoPagoAct ] = useState('');
  const [numero, setNumero] = useState(null);

  

  //Url para formualrio de Huespedes
  const [url, setUrl] = useState('');

  function generarURL() {
    setUrl(`${window.location.protocol + '//' + window.location.host}/huespedes/${id}`);
  }

  //Mensaje 
  const [mensaje, setMensaje] = useState('');

  const dispatch = useDispatch();

  
  function generarMensaje() {
    setMensaje('Actualizado correctamente');
  }

  //Obtien informacion de las reserva
    useEffect(() => {
      dispatch(obtenerInformacionVivienda());  
    }, []);

    const data = useSelector(state => state.viviendas.data);

    useEffect(() => {
      if (data) {
        setApi(data.vivienda)
        setNumHuespedes(data.numHuespedes)
        setFechaInicio(data.fechaInicio)
        setFechaSalida(data.fechaFin)
        setHuespedesRegistrados(data.huespedes)
        setTipoPago(data.tipoPago)
      }
    }, [data]);
    
  
    fetch(`${process.env.REACT_APP_API}` + api)
      .then(response => response.json())
      .then(data => {
        setNombre(data.nombre)
        setDireccion(data.direccion);
      });

  //Envia el numero de hupesedes
  const handleSubmit = (e) => {
    e.preventDefault();
    
    //Si el numero es mas pequeño que los usuarios registrados, hace pregunta
    if (numero < huespedesRegistrados.length) {

      if (window.confirm("Ya hay mas de " + numero + " usuarios registrados, si acpetas se borraran los " + (huespedesRegistrados.length - numero) + " ultimos huespedes registrados. ¿Está seguro que desea hacer esto? ")) {
        
        //Envia numero de huespedes a borrar
        dispatch(eliminarHuesped(huespedesRegistrados.slice(- (huespedesRegistrados.length - numero))));

        const informacion = {
          numHuespedes: numero,
          tipoPago: tipoPagoAct
          
          }
        
        dispatch(enviarNumHuespedes(informacion));

      } else {
        // El usuario ha hecho clic en "no"
        // Coloque aquí la lógica para manejar el rechazo
      }
    } 
    else {
      
      const informacion = {
        numHuespedes: numero,
        tipoPago: tipoPagoAct
        }

      
      
      dispatch(enviarNumHuespedes(informacion));

    }
   
   
  };

       
  if (!nombre) {
    return (
      <div>No exite esta reserva</div>
    )
  } 
     
    return (

      <div>
          
          <form className="checkin-form">
            <h2>Detalles</h2>
            
              <label for="nombre" className="checkin-label">
              Nombre de la vivienda:
              </label>
                <input id="nombre" type="text" value={nombre} className="checkin-input" />
              
          
              <label for="direccion" className="checkin-label">
              Dirección:
              </label>
                <input id="direccion" type="text" value={direccion} className="checkin-input" />
              

              <label for="fechaEntrada" className="checkin-label">
              Fecha de Entrada:
              </label>
                <input id="fechaEntrada" type="text" value={moment(fechaInicio).format('DD/MM/YYYY')} className="checkin-input" />
              

              <label for="fechaSalida" className="checkin-label">
              Fecha de Salida:
              </label>
                <input id="fechaSalida" type="text" value={moment(fechaSalida).format('DD/MM/YYYY')} className="checkin-input" />
              

              <label for="numeroHuespedes" className="checkin-label">
              Número de huespedes:
              </label>
                <input id="numeroHuespedes" type="text" 
                value={numHuespedes} className="checkin-input" />

                <label for="numeroHuespedes" className="checkin-label">
              Tipo de Pago:
              </label>
                <input id="numeroHuespedes" type="text" 
                value={tipoPago} className="checkin-input" />
              
              </form>  
              <br />
              <br />
              <form onSubmit={handleSubmit} className="checkin-form">
              <h2>Actualizar/Añadir </h2>
                <label htmlFor="numero"className="checkin-label" >Selecciona el número de Huespedes:</label>
                  <select id="numero" className="checkin-input" onChange={(e) => setNumero(parseInt(e.target.value))} required>
                  <option value="">-- Selcciona el numero de Huespedes</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>

                <label htmlFor="tipo_pago" className="checkin-label">Forma de Pago</label>
                <div class="form-group">
                  <label for="efectivo">Efectivo</label>
                  <input type="radio" name="pago" value="EFECT" id="efectivo" onChange={(e) => setTipoPagoAct(e.target.value)} required/>
                  <label for="tarjetaCredito">Tarjeta de crédito</label>
                  <input type="radio" name="pago" value="TARJT" id="tarjetaCredito" onChange={(e) => setTipoPagoAct(e.target.value)} required/>
                  <label for="plataformaPago">Plataforma de pago</label>
                  <input type="radio" name="pago" value="PLATF" id="plataformaPago" onChange={(e) => setTipoPagoAct(e.target.value)} required/>
                  <label for="transferencia">Transferencia</label>
                  <input type="radio" name="pago" value="TRANS" id="transferencia" onChange={(e) => setTipoPagoAct(e.target.value)} required/>
                  <label for="pagoMovil">Pago por móvil</label>
                  <input type="radio" name="pago" value="MOVIL" id="pagoMovil" onChange={(e) => setTipoPagoAct(e.target.value)} required/>
                </div>
              <br />
              <br />
              <button type="submit" className="checkin-button" onClick={generarMensaje}>Actualizar</button> {mensaje}
          </form>
          <br />
          <br />
          <div className="checkin-form">
              <button type="submit" className="checkin-button" onClick={generarURL}>Generar URL</button>  
              <br />
              <a href={url}> {url}</a>

          </div>
          <br />
          <br />
        </div>
  ) 
}



export default Viviendas;



