export const ENVIAR_NUMHUESPEDES = 'ENVIAR_NUMHUESPEDES';
export const ENVIAR_NUMHUESPEDES_EXITO = 'ENVIAR_NUMHUESPEDES_EXITO';
export const ENVIAR_NUMHUESPEDES_ERROR = 'ENVIAR_NUMHUESPEDES_ERROR';

export const OBTENER_INFORMACION = 'OBTENER_INFORMACION';
export const OBTENER_INFORMACION_SUCCESS = 'OBTENER_INFORMACION_SUCCESS';

export const ELIMINAR_HUESPED = 'ELIMINAR_HUESPED';
export const ELIMINAR_HUESPED_SUCCESS = 'ELIMINAR_HUESPED_SUCCESS';

