import {React} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { browserHistory } from './helpers/history';

//Redux
import {Provider } from 'react-redux';
import store from './store'; 

import {
  checkIndexAuthorization,
  checkWidgetAuthorization,
} from './lib/check-auth'


import Demo from './demo/Demo'
import Reservas from './reservas/Reservas'
import Viviendas from './viviendas/Viviendas'
import Huespedes from './huespedes/Huespedes'
import Login from './login/Login'
import Logout from './logout';
import Configuracion from './configuracion/Configuracion';
import Micuenta from './micuenta/micuenta';


function App() {
  return (
    <Provider store={store}>
    <Router location={browserHistory.location} navigator={browserHistory}>
      <Routes>
      <Route path="/" element={<Login/>} />
      <Route path="/demo" element={<Demo/>} />
      <Route path="/reservas" element={<Reservas/>} onEnter={checkWidgetAuthorization(store)}/>
      <Route path="/viviendas/:id" element={<Viviendas/>} onEnter={checkWidgetAuthorization(store)}/>
      <Route path="/huespedes/:id" element={<Huespedes/>} onEnter={checkWidgetAuthorization(store)}/>
      <Route path="/logout" element={<Logout/>} />
      <Route path="/configuracion" element={<Configuracion/>} />
      <Route path="/micuenta" element={<Micuenta/>} />


      </Routes>
    </Router>
  </Provider>
  );
}

export default App;
