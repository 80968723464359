import React, { useState,useEffect } from "react";
import { fetchData, obtenerDatosVivienda, activarPendiente } from "./actions";
import { unsetClient } from "../client/actions";
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import ListaReservas from "../componentes/listaReservas/listaReservas";

// import {KTIcon, toAbsoluteUrl} from '../helpers/index'


const Reservas = (className) => {


  const [datosReservas, setDatosReservas] = useState([]);

  const dispatch = useDispatch();

  const data = useSelector(state => state.reservas.data);

  const username = useSelector(state => state.client.decode.username);


  useEffect(() => {
      dispatch(fetchData());
  }, []);
  

  useEffect(() => {
    if (data) {
      setDatosReservas(data);
    }

  }, [data]);

  
  

  return (

    <ListaReservas datosReservas={datosReservas} className={className} username={username} />
    
  ) 
}

export default Reservas;



