import {combineReducers} from 'redux';
/*import client from '../client/reducer'
import signup from '../registro/reducer'
import login from '../login/reducer'
import dashboard from '../dashboard/reducer'
import campaign from '../campaign/reducer'
import verificacion from '../doblefactor/reducer'
import datoscuenta from '../datoscuenta/reducer'
import plan from '../planes_comprar/reducer'
import dashboard_influencer from '../dashboard_influencer/reducer'
import recovery from '../password_recovery/reducer'
*/
import demo from '../demo/reducer'
import reservas from '../reservas/reducer'
import viviendas from '../viviendas/reducer'
import huespedes from '../huespedes/reducer'
import client from '../client/reducer'
import login from '../login/reducer'
import micuenta from '../micuenta/reducer'

export default combineReducers({
/*  signup,
  client,
  login,
  dashboard,
  campaign,
  verificacion,
  datoscuenta,
  plan,
  dashboard_influencer,
  recovery*/
  demo,
  reservas,
  viviendas,
  huespedes,
  client,
  login,
  micuenta,
})