import {
    ENVIAR_HUESPED,
    ENVIAR_HUESPED_EXITO,
    ENVIAR_HUESPED_ERROR,
    EDITAR_HUESPED,
    EDITAR_HUESPED_EXITO,
    EDITAR_HUESPED_ERROR,
    OBTENER_INFORMACION_HUESPED,
    OBTENER_INFORMACION_HUESPED_SUCCESS
  } from './constants'

  const initialState = {
    enviando: false,
    exito: false,
    error: [],
    data: null,
    huespeds: null
  };

  const reducer = function enviarReducer (state = initialState, action) {
    switch (action.type) {
      case ENVIAR_HUESPED:
        return { ...state, enviando: true };
      case ENVIAR_HUESPED_EXITO:
        return {
          ...state,
          enviando: false,
          exito: true,
          error: null,
          data: action.data,
        };
      case ENVIAR_HUESPED_ERROR:
        return {
          ...state,
          enviando: false,
          exito: false,
          error: action.error,
          data: null,
        };
      case EDITAR_HUESPED:
        return { ...state, enviando: true };
      case EDITAR_HUESPED_EXITO:
        return {
          ...state,
          enviando: false,
          exito: true,
          error: null,
          data: action.data,
        };
      case EDITAR_HUESPED_ERROR:
        return {
          ...state,
          enviando: false,
          exito: false,
          error: action.error,
          data: null,
        };
      case OBTENER_INFORMACION_HUESPED:
          return { ...state};
      case OBTENER_INFORMACION_HUESPED_SUCCESS:
        return {
          ...state,
          exito: true,
          error: null,
          data: null,
          huespeds: action.data
        };
      default:
        return state;
        }
}
  
  export default reducer