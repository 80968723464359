export const ENVIAR_HUESPED = 'ENVIAR_HUESPED';
export const ENVIAR_HUESPED_EXITO = 'ENVIAR_HUESPED_EXITO';
export const ENVIAR_HUESPED_ERROR = 'ENVIAR_HUESPED_ERROR';

export const EDITAR_HUESPED = 'EDITAR_HUESPED';
export const EDITAR_HUESPED_EXITO = 'EDITAR_HUESPED_EXITO';
export const EDITAR_HUESPED_ERROR = 'EDITAR_HUESPED_ERROR';

export const OBTENER_INFORMACION_HUESPED = 'OBTENER_INFORMACION_HUESPED';
export const OBTENER_INFORMACION_HUESPED_SUCCESS = 'OBTENER_INFORMACION_HUESPED_SUCCESS';


