import {
    FETCH_VIVIENDAS_REQUEST,
    FETCH_VIVIENDAS_SUCCESS,
    FETCH_VIVIENDAS_FAILURE,
  } from './constants';
  
  export const fetchViviendasRequest = () => ({
    type: FETCH_VIVIENDAS_REQUEST,
  });
  
  export const fetchViviendasSuccess = (data) => ({
    type: FETCH_VIVIENDAS_SUCCESS,
    payload: data,
  });
  
  export const fetchViviendasFailure = (error) => ({
    type: FETCH_VIVIENDAS_FAILURE,
    error,
  });