import {
    ENVIAR_NUMHUESPEDES,
    ENVIAR_NUMHUESPEDES_EXITO,
    ENVIAR_NUMHUESPEDES_ERROR,
    OBTENER_INFORMACION,
    OBTENER_INFORMACION_SUCCESS,
    ELIMINAR_HUESPED,
    ELIMINAR_HUESPED_SUCCESS
    
  } from './constants'

  const initialState = {
    enviando: false,
    exito: false,
    error: [],
    data: null,
  };

  const reducer = function enviarReducer (state = initialState, action) {
    switch (action.type) {
      case ENVIAR_NUMHUESPEDES:
        return { ...state, enviando: true };
      case ENVIAR_NUMHUESPEDES_EXITO:
        return {
          ...state,
          enviando: false,
          exito: true,
          error: null,
          data: action.data,
        };
      case ENVIAR_NUMHUESPEDES_ERROR:
        return {
          ...state,
          enviando: false,
          exito: false,
          error: action.error,
          data: null,
        };
      case OBTENER_INFORMACION:
          return { ...state};
      case OBTENER_INFORMACION_SUCCESS:
        return {
          ...state,
          exito: true,
          error: null,
          data: action.data,
        };
      case ELIMINAR_HUESPED:
          return { ...state};
      case ELIMINAR_HUESPED_SUCCESS:
        return {
          ...state,
          exito: true,
          error: null,
          data: action.data,
        };
        
      default:
        return state;
        }
}
  
  export default reducer