import React, { useState,useEffect } from "react";
import { useParams } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { enviarDatoshuesped, obtenerInformacionHuesped } from './actions';
import { obtenerInformacionVivienda  } from '../viviendas/actions';

import Formulario from "../componentes/formulario";
import FormularioEdit from "../componentes/formularioEdit";

const Huespedes = () => {

    //Id de la reserva
    const { id } = useParams();

    //Enviar id a Sagas
    const idSaga = id
    window.localStorage.setItem('idSaga', idSaga)

    ////////Datos de la reserva
    //Numero de huespedes que tiene la reserva
    const [numHuespedes, setNumHuespedes ] = useState('');
    //Datos huespedes registrados
    const [datosHuespedesReg, setDatosHuespedesReg ] = useState([]);
    //Numero de huespedes ya registrados
    const [huespedesRegistrados, setHuespedesRegistrados ] = useState('');
    //@id de la reserva para la relacion con huesped
    const [reserva, setReserva] = useState('');

    const [informacionHuesped, setInformacionHuesped] = useState([]);


    const data = useSelector(state => state.viviendas.data);

    const huespeds = useSelector(state => state.huespedes.huespeds);


    const dispatch = useDispatch();

    //Informacion de la reserva
    useEffect(() => {
      dispatch(obtenerInformacionVivienda());
    }, []);

    useEffect(() => {
      if (data) {
        setNumHuespedes(data.numHuespedes)
        setReserva(data["@id"])
        setHuespedesRegistrados(data.huespedes.length)
        setDatosHuespedesReg(data.huespedes)
      }

    }, [data]);

    //Informacion de los huepsedes
    useEffect(() => {
      dispatch(obtenerInformacionHuesped(datosHuespedesReg));
    }, [datosHuespedesReg]);

    useEffect(() => {
      if (huespeds) {
        setInformacionHuesped(huespeds)
      }

    }, [huespeds]);


    const formularios = [];
    for (let i = 0; i < (numHuespedes - huespedesRegistrados); i++) {
      formularios.push(
        <Formulario index={i} reserva={reserva}/>
      );
    }

    const formulariosRellenos = [];

    formulariosRellenos.push(
      <div>
      {informacionHuesped.map((item, index) => (
      <FormularioEdit  item={item} index={index} reserva={reserva}/>
      ))}   
      </div>
        
    );


    return (
      <div>

        <h2>Usuarios registrados: </h2>
        <p>{huespedesRegistrados} de {numHuespedes}</p>
        {formulariosRellenos}
        <br />
        <br />
        <br />
        {formularios}
      </div>
    );
  };



export default Huespedes;