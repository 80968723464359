import React, { useState,useEffect } from "react";
import moment from 'moment';
import { obtenerDatosVivienda, activarPendiente } from "../../reservas/actions";
import {useDispatch, useSelector} from 'react-redux';
import InputNombreVivienda from "./InputNombreVivienda";


const FilaListaReservas = ({ item, index}) => {

    const dispatch = useDispatch();

    const actualizarPendiente = () => {
        dispatch(activarPendiente(item.id));
    }


  return (
            <tr key={index}>

            <td>
            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                <input className='form-check-input widget-9-check' type='checkbox' value='1' />
            </div>
            </td>
            <td>
          
            <InputNombreVivienda  nombre={item.vivienda} />
            </td>
            <td>
            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                {moment(item.fechaInicio).format('DD/MM/YYYY')}
            </a>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                Entrada
            </span>
            </td>
            <td>
            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
            {moment(item.fechaFin).format('DD/MM/YYYY')}
            </a>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                Salida
            </span>
            </td>
            <td className='text-end'>
            <div className='d-flex flex-column w-100 me-2'>
                <div className='d-flex flex-stack mb-2'>
                <span className='text-muted me-2 fs-7 fw-semibold'>
                    {item.numHuespedes ?

                    <span>{item.huespedes.length} de {item.numHuespedes} usuarios registrados</span>
                    :

                    <span>No se ha añadido numero de huespedes</span>

                    }
                    </span>
                </div>

                <div className='progress h-9px w-100'>
                {item.numHuespedes == item.huespedes.length?

                    <div
                    className=' proggresbarComplet'
                    role='progressbar'
                    style={{width: (item.huespedes.length*100)/item.numHuespedes + '%'}}
                    ></div>

                    :

                    <div
                    className='progress-bar bg-primary'
                    role='progressbar'
                    style={{width: (item.huespedes.length*100)/item.numHuespedes + '%'}}
                    ></div>

                }
                </div>
            </div>
            </td>
            <td>
            <div className='text-dark fw-bold text-hover-primary d-block fs-6'>
                {/* Se ha notificado al ministerio*/}
                {item.ministerio ? 
                <span>Si
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    Ha sido notificado el {moment(item.fechaNotificacion).format('DD/MM/YYYY')}
                    </span>
                </span>
                :  
                <span>No
                    {/* Esta pendiendte de notificar*/}
                    {item.pendiente ? 
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        Pendiente de Notificar
                        </span>
                    :   
                        <div>
                            {item.huespedes.length === item.numHuespedes ?
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                Se puede notificar
                                </span>
                            :
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                Deben registrarse todos los usuarios para notificar
                                </span>
                            }
                        </div>
                        
                    }
                    
                </span> 
                }
            </div>
            
            </td>
            <td>
            <div className='d-flex justify-content-end flex-shrink-0'>
                {!item.pendiente ? 
                <div>
                { item.huespedes.length === item.numHuespedes ?
                    <button
                    className='checkin-button'
                    onClick={actualizarPendiente}
                    >
                    Notificar
                    {/* <KTIcon iconName='switch' className='fs-3' /> */}
                    </button>

                    :

                    <button
                    className='btn btn-active-color-primary'
                    disabled>
                    Notificar
                    {/* <KTIcon iconName='switch' className='fs-3' /> */}
                    </button>
                }
                </div>
                :
                <div></div>
                }
                &nbsp;
                <a
                href={`viviendas/${item.id}`}
                className='checkin-button'
                >
                Detalles
                {/* <KTIcon iconName='switch' className='fs-3' /> */}
                </a>

            </div>
            </td>
        </tr>
    )
};

export default FilaListaReservas;